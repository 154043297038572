import Container from 'src/components/helpers/Container'
import Title from 'src/components/modules/hero/Title'
import SubTitle from 'src/components/modules/hero/SubTitle'
import {useStyles} from './styles'

import SearchBox from 'src/components/modules/home/homeHero/SearchBox'
import MobileSearch from 'src/components/modules/home/homeHero/mobileSearch/MobileSearch'

export default function SearchHero(props: {
  title?: string
  subTitle?: string
  breadcrumb?: React.ReactNode
  justSearch?: boolean
  maxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | false
  defaultSearchValue?: string | null
}) {
  const {
    breadcrumb,
    title,
    subTitle,
    justSearch = false,
    maxWidth = 'lg',
    defaultSearchValue,
  } = props

  const classes = useStyles({maxWidth})

  if (justSearch) {
    return (
      <div>
        <Container className={classes.justSearchContainer} maxWidth={maxWidth}>
          <div className={classes.desktopSearch}>
            <SearchBox defaultSearchValue={defaultSearchValue} />
          </div>
          <div className={classes.mobileSearch}>
            <MobileSearch elivated />
          </div>
        </Container>
      </div>
    )
  }

  return (
    <div className={classes.root}>
      <Container className={classes.container} maxWidth={maxWidth}>
        {breadcrumb && (
          <div className="text-white flex flex-row justify-start items-center">
            {breadcrumb}
          </div>
        )}
        <div className="lg:pt-6">
          <Title title={title} white left />
          {subTitle && <SubTitle white left subTitle={subTitle} />}
        </div>

        <div className={classes.desktopSearch}>
          <SearchBox defaultSearchValue={defaultSearchValue} />
        </div>
        <div className={classes.mobileSearch}>
          <MobileSearch elivated />
        </div>
      </Container>
    </div>
  )
}
